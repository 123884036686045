@import 'variables';

.ant-form {
    font-size: 0.875rem;
}

.ant-checkbox-inner {
    border-radius: 2px;
}

.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #AFB2BC;
    border-color: #AFB2BC;

    &:after{
        border-color: white;
    }
}

.ant-checkbox-disabled + span {
    color: #6C6C6C;
}

.ant-select-arrow {
    right: 28px;
}

.ant-select-selector {
    padding: 0 !important;;
}

.ant-input-group-addon .ant-select-arrow {
    right: 10px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 26px;
    padding-right: 50px;
}

.phone-input .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
    padding-left: 0;
    padding-right: 0;
}

.ant-select-single .ant-select-selector .ant-select-selection-search,
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    left: 26px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: inherit;
};

.ant-input {
    filter: none;
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.ant-select-selection-search-input {
    padding-top: 12px !important;
};

.ant-picker-large .ant-picker-input > input,
.ant-input-affix-wrapper > input.ant-input {
    border-radius: 0;
}

.ant-dropdown-menu {
    overflow: hidden;
}

.form-field-select .ant-select-arrow {
    user-select: none;
    width: 24px;
    height: 24px;
    margin-top: -12px;
}

.ant-select-item-option-content {
    white-space: normal !important;
}
