@import 'variables';

*, *::before, *::after {
    box-sizing: border-box;
}

a {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
}

@keyframes onAutoFillStart {
    from { /**/
    }
    to { /**/
    }
}

@keyframes onAutoFillCancel {
    from { /**/
    }
    to { /**/
    }
}

input:-webkit-autofill {
    animation-name: onAutoFillStart;
}
input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
}
input:not(:-webkit-autofill),
input:-webkit-autofill {
    animation-iteration-count: 10;
    animation-duration: 50ms;
}
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,

textarea {
    resize: none;
}

button {
    cursor: pointer;
}

html {
    font-size: 16px;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

body {
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: "tnum","tnum";
    font-feature-settings: "tnum","tnum";
    margin: 0;
    padding: 0;
    background-color: #f3f6f4;
    font-size: 0.875rem;
    font-family: 'DM Sans', sans-serif;
    color: #231f20;
    min-height: 100vh;
    word-break: break-word;
}

h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: .5em;
    color: rgba(0,0,0,.85);
    font-weight: 500
}

dl,ol,ul {
    margin-top: 0;
    margin-bottom: 1em
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

input::-ms-clear,input::-ms-reveal {
    display: none
}

input[type=number],input[type=password],input[type=text],textarea {
    -webkit-appearance: none
}

button,input,optgroup,select,textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit
}

button,input {
    overflow: visible
}

p {
    font-size: 0.875rem;
    margin-top: 0;
    margin-bottom: 1em
}

.form_item_wrapper {
    position: relative;
    padding-bottom: 16px;
}

.form_item_wrapper .form_item_label {
    position: absolute;
    top: 0;
    left: 0;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transform-origin: top left;
    transform: translate(10px, 12px) scale(1);
    padding: 0px 8px;
    color: #6C6C6C;
    font-size: 14px;
    z-index: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 5px;
}

.form_item_label.focused {
    color: @text-primary;
}

.form_item_wrapper.form_item_group .form_item_label {
    transform: translate(110px, 12px) scale(1);
}

.form_item_wrapper.form_item_filled .form_item_label {
    transform: translate(10px, -10px) scale(0.75);
    background: #ffffff;
}

.form_item_wrapper.form_item_filled .form_item_label {
    color: #000000;
}

.form_item_wrapper.form_item_focused .form_item_label {
    color: #02C172;
}

.form_item_wrapper.form_item_error .form_item_label {
    color: #FF4958;
}

.form_item_wrapper.form_item_group.form_item_filled .form_item_label {
    transform: translate(110px, -10px) scale(0.75);
    background: #ffffff;
}

.form_item_wrapper:not(.form_item_filled) .form_item_label {
    right: 70px;
    pointer-events: none;
}

.form_item_error .form_item_error {
    margin-top: 5px;
    font-size: 13px;
    color: #ed4203;
}

// fix chrome autofill changing font/font-size
&:-webkit-autofill::first-line,
&:-webkit-autofill,
&:-webkit-autofill:hover,
&:-webkit-autofill:focus,
&:-webkit-autofill:active {
    font-size: 18px !important;
    font-family: 'DM Sans', sans-serif;
}
